<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-card-title>Подтвердите действие</v-card-title>

      <v-divider/>

      <v-card-text class="pt-4 body-1">
        Подтвердите, что хотите {{ itemState }} признак “Отображать на web“
        для маршрута № {{ selected.vcroutesRoutecode }}
      </v-card-text>

      <v-divider/>

      <v-card-actions>
        <v-btn
          @click="show = false"
          color="red"
          outlined
        >
          Отмена
        </v-btn>

        <v-spacer/>

        <v-btn
          @click="execute"
          :loading="loading"
          color="primary"
          dark
        >
          Подтвердить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DisplayWeb',
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      show: true,
      selected: null,
      currentMode: null,
      loading: false,
      changedMode: null,
    }),
    created() {
      this.selected = this.collection.master.selected;

      if (!this.selected){
        jet.msg({
          text:'Необходимо выбрать запись!',
          color:'warning'
        });
        return;
      }

      this.currentMode = this.selected.vcroutesDisplayonweb;
      this.changedMode = !this.currentMode;
    },
    computed: {
      itemState() {
        return this.changedMode ? 'установить' : 'снять';
      },
    },
    methods: {
      async execute() {
        this.loading = true;
        const routeId = this.selected.vcroutesId;
        let mode = '';

        try {
          const result = await this.changeMode(routeId, this.changedMode);
          if (result) {
            mode = this.changedMode ? 'Установка' : 'Снятие'
            this.collection.refresh(routeId);
          }

          jet.msg({
            text: `${mode} признака "Отобразить на Web" 
              для маршрута № ${this.selected.vcroutesRoutecode} 
              ${this.selected.vcroutesRoutename} выполнено успешно!`
          });
        } catch (ex) {
          jet.msg({
            text: 'Невозможно изменить параметр "Отображение на web".'
            + ' Попробуйте снова или обратитесь в службу поддержки',
            color:'warning',
          });
          return;
        }
        this.show = false;
        this.loading = false; 
      },
      async changeMode(routeId, mode) {
        const changeMode = {
          type: 'core-update',
          query: 'sin2:/v:acd2f542-598c-4540-b46c-03375098d467/',
          params: [
            { id: 'ID', type: 'id', value: routeId },
            { id: 'displayOnWeb', type: 'boolean', value: mode },
          ]
        };
        const response = await jet.http.post(changeMode);
        if (response.error) {
          throw response.error;
        }
        return true;
      }
    }
  };
</script>
